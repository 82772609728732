.subheader {
  display: flex;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}
.stepper {
  display: flex;
}

.stepper__item--concurrent {
  color: yellow;
}
.stepper-vertical,
.stepper-vertical__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.stepper-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.page-layout-mt {
  margin-top: 16px;
}

.stepper-vertical__item {
  position: relative;
  padding-bottom: 16px;
  text-decoration: none;
  color: #fff;
}

.stepper-vertical__item:last-child:before {
  content: none;
}
.stepper-vertical__item:before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  top: 32px;
  bottom: 0;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 1px;
  border: 2px solid #006298;
}

.stepper-vertical__step-container {
  margin-right: 16px;
}

.ticket-dialog__content .stepper-vertical__step {
  color: #000;
}

.stepper-vertical__step {
  box-sizing: initial;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 3px solid #006298;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1rem;
  text-decoration: inherit;
  text-transform: inherit;
  font-weight: 700;
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-left: 3.75px;
}

.stepper-vertical__details {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.stepper-vertical__step-title {
  margin-bottom: 8px;
  font-family: Roboto Condensed, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.75rem;
  text-decoration: inherit;
  text-transform: inherit;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  color: var(--theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.data-table--fullwidth {
  width: 100%;
}
.data-table--fixed {
  table-layout: fixed;
}

.data-table,
.outcome-list-simple__name-secondary {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* font-size: 0.75rem; */
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1rem;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.54);
  color: var(--theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.ticket-dialog__content .data-table__cell {
  padding: 8px 16px 8px 0;
}

.data-table__cell {
  padding: 0;
  vertical-align: top;
}

.data-table__cell--nowrap {
  white-space: nowrap;
}

.data-table__cell--text-primary {
  color: #27b7e6;
  color: var(--theme-primary, #27b7e6);
  font-weight: 700;
}

.content-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  color: var(--theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.content-card__primary {
  padding: 16px;
}

.content-card {
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #fff;
  background-color: var(--theme-background, #fff);
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1rem;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  color: var(--theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.icon-input {
  position: relative;
  border-bottom: 1px solid #fff;
}

.list__icon {
  margin-right: 4px;
  margin-left: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  color: var(--theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.icon-input .icon {
  color: rgba(0, 0, 0, 0.38);
  color: var(--theme-text-hint-on-secondary-light, rgba(0, 0, 0, 0.38));
  left: 8px;
}

.icon-input .icon,
.icon-input .icon-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
svg:not(:root) {
  overflow: hidden;
}
.icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.icon-input .text-input--primary {
  padding-left: 48px;
}

.text-input--primary {
  border: none;
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.38);
  border-bottom-color: var(
    --theme-text-hint-on-secondary-light,
    rgba(0, 0, 0, 0.38)
  );
  padding: 16px;
  width: 100%;
}

.list:last-of-type {
  padding-bottom: 16px;
}

.list {
  margin: 0;
  padding: 8px 0 0;
  list-style-type: none;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  color: var(--theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.938rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.25rem;
  text-decoration: inherit;
  text-transform: inherit;
}

.list__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 8px;
  height: 52px;
  color: inherit;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list__avatar {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

.list__avatar,
.list__graphic {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.list__name {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  color: var(--theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.25rem;
}

.page-layout-pt {
  padding-top: 16px;
}

.wrapper--narrow {
  max-width: 608px;
  margin: auto;
  padding: 0 8px;
}
hr {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.list__divider {
  margin: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.empty-state {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 200px;
}
.empty-state__caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1rem;
}

.empty-state__caption,
.empty-state__label {
  color: rgba(0, 0, 0, 0.54);
  color: var(--theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-decoration: inherit;
  text-transform: inherit;
}

.empty-state__label {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.75rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.empty-state__image {
  margin-top: 16px;
  margin-bottom: 8px;
  min-width: 100px;
  max-width: 360px;
  height: auto;
}

.is-positive {
  color: #60ba00;
  font-weight: 700;
}

.is-negative {
  color: #d12219;
  font-weight: 700;
}

.form-validation__inline--error {
  color: #d12219;
}

.alert {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1rem;
  text-decoration: inherit;
  text-transform: inherit;
  color: #fff;
  color: var(--theme-text-primary-on-primary, #fff);
  background-color: #148bb1;
  background-color: var(--theme-primary-dark, #148bb1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  /* margin-bottom: 1.75em; */
  padding: 1em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
}

.alert__icon {
  margin-right: 0.5em;
}

.icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.sign-up-alert {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1rem;
  text-decoration: inherit;
  text-transform: inherit;
  line-height: 1.35em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: -1em;
  margin-right: -1em;
  padding: 1.5em 2em;
}
.alert--small,
.alert--small .alert__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.alert--small {
  border: none;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5em;
  text-align: left;
}

.sign-up-verify-header {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.25rem;
  text-decoration: inherit;
  text-transform: inherit;
}
.sign-up-verify-copy,
.sign-up-verify-link {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1rem;
  text-decoration: inherit;
  text-transform: inherit;
}
.sign-up-verify-link a {
  text-decoration: underline;
}
.form-page__link {
  color: #fff;
  color: var(--theme-text-primary-on-primary, #fff);
  font-weight: 600;
  text-decoration: none;
}
.avatar-upload__image {
  margin: auto;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  background: hsla(0, 0%, 100%, 0.2);
  border-color: #27b7e6;
  border: 3px solid var(--theme-primary, #27b7e6);
}
.is-hidden {
  visibility: hidden !important;
  display: none;
}
