body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-y: scroll;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slick-slider {
  position: relative;
}

.slick-track {
  display: flex;
}

.slick-slide {
  transition: ease 0.2s transform;
  transform: scale(0.94);
}

.slick-center, .slick-active {
  transform: scale(1.12);
}

@media (max-width: 540px) {
  .slick-slide {
    transition: ease 0.2s transform;
    transform: scale(0.88);
  }
  
  .slick-center, .slick-active {
    transform: scale(1.24);
  }
}